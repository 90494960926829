import TablePage from '@/found/components/table_page';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  name: 'inventoryCollect',
  data() {
    return {

    };
  },
  created() {
    this.getConfigList('page_list');
  },

  methods: {
    modalClick({ val, row }) {
      if (val.code === 'inventory_details') {
        console.log(row.id);
        this.modalConfig.title = '库存明细';
        this.propsObjInData.id = row.id;
        this.openFull();
      }
    },
  },
};
