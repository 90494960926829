import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  props: {
    propsObj: Object,
  },
  data() {
    return {
      params: {
        repertoryId: this.propsObj.id,
      },
    };
  },
  created() {
    console.log(this);
    this.getConfigList('details_list');
  },

  methods: {

  },
};
